.blog-detail {
	width: 100%;
	max-width: 1260px;
	margin: 47px auto 60px;

	@include breakpoint(large) {
		margin-bottom: 100px;
	}

	&__main {
		@include breakpoint(small only) {
			text-align: center;
			padding-right: 20px;
			margin-bottom: 81px;
		}

		@include breakpoint(medium down) {
			padding-left: 20px;
		}

		@include breakpoint(medium) {
			padding-right: 60px;
		}

		h2 {
			@include subheading();
			margin-bottom: 29px;
		}
	}

	.intro {
		margin-bottom: 0;
		max-width: none;
		padding: 0;

		@include breakpoint(medium) {
			text-align: left;
		}
	}

	&__post-date {
		@include subheading();
	}

	&__post-categories {
		margin: 0 0 30px;
		list-style-type: none;
		@include subheading();

		li {
			display: inline;

			&::after {
				content: ', ';
			}

			&:last-child::after {
				content: '';
			}
		}

		a {
			color: inherit;
		}
	}

	&__content {
		p {
			@include body-copy();
		}

		p img {
			width: 100% !important;
			max-width: 100% !important;
			height: auto !important;
		}
	}

	&__sidebar {
		&-inner {
			padding: 40px 20px;
			background-color: get-color('pale-grey');

			@include breakpoint(large) {
				padding: 40px;
			}
		}

		h3 {
			@include h2;
			margin-bottom: 40px;

			@include breakpoint(small only) {
				text-align: center;
			}
		}

		ul {
			margin: 0;
			list-style-type: none;
		}

		li {
			margin-bottom: 36px;
			@include smallest();

			&:last-child {
				margin-bottom: 0;
			}
		}

		img {
			margin-bottom: 20px;
		}

		span {
			position: relative;
			@include smallest();
			transition: all 0.3s ease-in-out;

			&::after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 0;
				border-bottom: 1px solid get-color('black');
				transition: width 0.3s ease-in-out;
			}

			&:hover,
			&:active {
				color: get-color('red');

				&::after {
					width: 30px;
					border-color: get-color('red');
				}
			}
		}
	}
}
