.intro {
	width: 100%;
	max-width: 1050px;
	margin: 0 auto 40px;
	padding: 48px 20px 39px;
	text-align: center;

	@include breakpoint(small only) {
		margin-bottom: 0;
		padding-bottom: 41px;
	}

	h1 {
		@include h1;
		margin-bottom: 26px;
	}

	h2 {
		@include subheading();
		margin-bottom: 32px;
	}

	p {
		@include body-copy;
		max-width: 513px;
		margin: 0 auto 19px;
	}

	.multiple-buttons {
		padding-top: 37px;
		text-align: center;
	}
}
