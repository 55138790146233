.home {
	padding-top: 20px;

	&__item {
		background-color: get-color('pale-grey');
		margin-bottom: 120px;

		@include breakpoint(small only) {
			margin-bottom: 0px;
		}
	}

	&__image {
		position: relative;
		min-height: 230px;

		@include breakpoint(medium) {
			min-height: 490px;
		}

		&-large {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		&-small {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(50%, -50%);
			border: 12px solid get-color('pale-grey');
			width: 184px;
			height: 55%;
			max-height: 272px;

			@include breakpoint(small only) {
				border-width: 8px;
				transform: none;
				top: initial;
				right: 20px;
				bottom: -75px;
				width: 124px;
				height: 124px;
				min-height: 0;
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__content {
		@include flex-align-center();
		text-align: left;
		padding-top: 50px;
		padding-bottom: 50px;
		padding-left: 20px;
		padding-right: 20px;

		@include breakpoint(small only) {
			padding-top: 120px;
			padding-bottom: 60px;
		}

		@include breakpoint(medium) {
			padding-left: 163px;
		}

		@include breakpoint(large) {
			padding-right: 105px;
		}

		&-inner {
			max-width: 360px;
		}
	}

	&__item--image-right {
		.home {
			&__image {
				@include breakpoint(medium) {
					order: 2;
				}

				&-small {
					@include breakpoint(small only) {
						right: initial;
						left: 20px;
					}

					@include breakpoint(medium) {
						right: initial;
						left: 0;
						transform: translate(-50%, -50%);
					}
				}
			}

			&__content {
				@include breakpoint(medium) {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					order: 1;
					padding-right: 163px;
					padding-left: 20px;
				}

				@include breakpoint(large) {
					padding-left: 105px;
				}
			}
		}
	}

	h2 {
		@include h2;
		margin-bottom: 20px;

		a {
			color: inherit;
		}
	}

	h3 {
		@include subheading();
		margin-bottom: 20px;
		color: get-color('black');

		a {
			color: inherit;
		}
	}

	p {
		@include body-copy();
	}

	.multiple-buttons {
		padding-top: 40px;
	}

	&__map {
		margin-bottom: 120px;

		@include breakpoint(small only) {
			margin-top: 60px;
			margin-bottom: 58px;
		}

		&-header {
			text-align: center;

			h2 {
				margin-bottom: 17px;

				@include breakpoint(small only) {
					margin-bottom: 9px;
				}
			}

			h3 {
				margin-bottom: 43px;
				color: get-color('mid-grey');
			}
		}

		&-map {
			position: relative;
			width: 100%;
			height: 510px;

			.map-container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

.crt-feed.crt-feed-branded {
	padding-bottom: 50px;
}
