.breadcrumb {
  text-align: center;
  padding-top: 11px;

  @include breakpoint(small only) {
    display: none;
  }

  &:first-child {
    margin-top: -30px;
  }

  &__list {
    display: inline-block;
    text-align: left;
    margin: 0;
    padding: 0 5px 5px 5px;
    list-style-type: none;

    li {
      display: inline-block;
      white-space: nowrap;
      @include smallest();
      color: get-color('red');

      a {
        color: get-color('black');
        transition: all 0.3s ease-in-out;

        &:hover {
          color: get-color('red');
        }
      }

      &:nth-child(1n+3) {
        &:before {
          content: '/';
          color: get-color('black');
          margin: 0 2px;
        }
      }
    }
  }
}
