.blog-landing {
	max-width: 1050px;
	margin: 0 auto;
	padding: 0 20px;
	text-align: center;

	@include breakpoint(large) {
		width: 83.33333%;
	}

	&__item {
		margin-bottom: 60px;

		&:not(.blog-landing__item--highlighted) {
			vertical-align: top;
			
			@include breakpoint(medium) {
				width: calc(50% - 34px);
				display: inline-block;
			}

			&:nth-child(even) {
				@include breakpoint(medium) {
					margin-right: 62px;
				}

				&:last-child {
					@include breakpoint(medium) {
						transform: translateX(34px);
					}
				}
			}
		}
	}

	&__image {
		position: relative;
		min-height: 340px;
		background: no-repeat center center;
		background-size: cover;

		a,
		picture {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__content {
		padding: 40px 20px;
		@include flex-align-center;
		background-color: get-color('pale-grey');

		&:is(.blog-landing__item--highlighted .blog-landing__content) {
			@include breakpoint(medium) {
				text-align: left;
			}

			@include breakpoint(large) {
				padding: 90px 60px;
			}
		}


		@include breakpoint(large) {
			padding: 40px 60px;
		}

		h2 {
			@include h2;
			margin-bottom: 14px;

			a {
				color: inherit;
			}
		}

		h3 {
			@include subheading();
			margin-bottom: 18px;
		}

		p {
			@include body-copy();
		}
	}

	&__post-date {
		@include subheading();
		margin-bottom: 13px;
	}

	&__post-categories {
		margin-left: 0;
		list-style-type: none;
		@include subheading();
		margin-bottom: 10px;

		li {
			display: inline;

			&::after {
				content: ', ';
			}

			&:last-child::after {
				content: '';
			}
		}

		a {
			color: inherit;
		}
	}

	&__cta {
		padding-top: 21px;

		&:is(.blog-landing__item--highlighted .blog-landing__cta) {
			padding-top: 41px;
		}

		.button {
			margin-bottom: 0;
		}
	}
}
