.popup {
	@at-root .reveal-overlay {
		z-index: 60000;
	}

	&.reveal {
		max-width: 630px;
		border: 0;
		padding: 0;
		overflow: visible;
		padding: 30px;
		background: get-color('black');

		@include breakpoint(small only) {
			padding: 20px;
		}

		&:focus {
			outline: none;
		}
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 21px;
		height: 21px;
		background: url('img/close-thin-white.svg') no-repeat center center;
		background-size: 21px 21px;
		text-indent: -12345px;
		cursor: pointer;

		@include breakpoint(small only) {
			top: 30px;
			right: 30px;
		}
	}

	&__text {
		padding: 15px;
		text-align: center;

		img {
			margin-bottom: 25px;
			width: 100%;
			max-width: 433px;
		}

		h2 {
			@include h2;
			color: get-color('white');
			margin-bottom: 37px;
		}

		p {
			@include body-copy();
			color: get-color('white');
		}

		.multiple-buttons {
			text-align: center;
			margin-top: 24px;

			.button {
				border: 1px solid get-color('white');
				color: get-color('white');
				font-size: 11px;
				font-weight: 700;
				letter-spacing: 1px;
				padding: 10px 20px;
				text-transform: uppercase;
				background-color: transparent;
				transition: all 0.3s ease-in-out;
				cursor: pointer;

				&:hover,
				&:active,
				&:focus {
					background-color: get-color('red');
					border-color: get-color('red');
					color: #fff;
				}

				&--primary {
					color: #fff;
					background-color: get-color('red');
					border-color: get-color('red');

					&:hover,
					&:active,
					&:focus {
						background-color: get-color('black');
						border-color: get-color('white');
						color: get-color('white');
					}
				}
			}
		}

		&-text {
			max-width: 414px;
			width: 100%;
			margin: auto;
		}
	}
}
