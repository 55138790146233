.footer {
	width: 100%;
	background-color: get-color('black');

	@include breakpoint(small only) {
		text-align: center;
	}

	&__newsletter {
		background-color: get-color('pale-grey');

		&-inner {
			max-width: 1280px;
			margin: 0 auto;
			padding: 30px 0 27px;

			@include breakpoint(medium down) {
				padding-right: 10px;
				padding-left: 10px;
			}
		}

		&-header {

			@include breakpoint(small only) {
				text-align: left;
			}
			
			@include breakpoint(medium) {
				padding-left: 10px;
			}
		}

		h2 {
			@include subheading();
			margin-bottom: 18px;
			color: get-color('black') !important;
		}

		input[type="text"],
		input[type="email"] {
			width: 100%;
			height: 33px;
			margin-bottom: 7px;
		}

		button[type="submit"],
		input[type="submit"] {
			height: 33px;
			width: 100%;

			@include breakpoint(small only) {
				min-width: 117px;
				width: auto;
			}
		}

		form .cell {
			padding: 0 10px;

			@include breakpoint(small only) {
				padding: 0;
				margin-bottom: 14px;
			}
		}

		label {
			@include smallest();
		}

		@include breakpoint(small only) {
			padding: 0 21px;
		}

		&-checkbox,
		&-submit {
			@include breakpoint(small only) {
				text-align: left;
			}
		}
	}

	&__main {
		background-color: get-color('black');
	}

	&__inner {
		max-width: 1260px;
		margin: 0 auto;
		padding: 50px 0 50px;

		@include breakpoint(medium down) {
			margin: 0 21px;
		}
	}

	&__social {
		margin-bottom: 20px;
		margin-top: 2px;
		list-style-type: none;
		text-align: right;

		li {
			display: inline-block;
			margin: 0 5px;

			@include breakpoint(medium) {
				margin: 0 0 0 19px;
			}
		}

		@include breakpoint(medium) {
			padding-right: 21px;
		}

		img,
		svg {
			width: auto;
			height: 17px;
		}

		path,
		circle {
			fill: #fff;
			transition: all 0.3s ease-in-out;
		}

		a:hover path,
		a:hover circle {
			fill: get-color('red');
		}

		@include breakpoint(small only) {
			text-align: center;
		}
	}

	&__primary,
	&__secondary {
		ul {
			margin: 0;
			list-style-type: none;
			transform: translateY(-8px);
		}

		li {
			white-space: nowrap;
		}

		a {
			position: relative;
			@include smallest();
			color: get-color('white');
			transition: all 0.3s ease-in-out;

			&::after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 0;
				border-bottom: 1px solid get-color('white');
				transition: width 0.3s ease-in-out;
			}

			&:hover,
			&:active {
				color: get-color('red');

				&::after {
					width: 30px;
					border-color: get-color('red');
				}
			}
		}
	}

	&__secondary {
        @include breakpoint(small only) {
            padding-top: 20px;
        }
    }

	&__copyright {
		@include terms-conditions();
		color: get-color('white');

		a {
			color: inherit;
			transition: all 0.3s ease-in-out;


			&:hover,
			&:active,
			&:focus {
				color: get-color('red');
			}
		}

		@include breakpoint(medium only) {
			padding-right: 20px;
			margin-top: 20px;
		}

		@include breakpoint(small only) {
			margin: auto;
			text-align: center;
			padding-top: 53px;
		}
	}

	ul.footer__social {
		@include breakpoint(medium down) {
			margin-left: 0;
			text-align: left;
		}

		@include breakpoint(medium only) {
			margin-top: 20px;
		}

		@include breakpoint(small only) {
			margin-top: 20px;
			text-align: center;
		}

		li {
			@include breakpoint(medium down) {
				margin-left: 0;
				margin-right: 10px;
			}

			@include breakpoint(small only) {
				margin: 0 5px;

			}
		}
	}
}
