.social-wall {
	// margin: -10px auto 25px;

	h3 {
		@include h2;
		margin-bottom: 14px;

		a {
			color: inherit;
		}
	}

	&__heading {
		margin-left: auto;
  	margin-right: auto;
		text-align: center;
  	max-width: 1050px;
	}

	p {
		@include subheading();
		margin-bottom: 35px;

		a {
			color: inherit;
		}
	}

	.crt-feed {
		max-width: 1070px;
		width: 100%;
		margin: auto;
	}

	@include breakpoint(small only) {
		margin: 0 21px;

		.social-wall__feed .crt-grid-post {
			width: 50% !important;
		}
		
		.social-wall__feed .crt-grid-post:first-child {
			padding-right: 8px !important;
		}
	}

	.crt-grid-post {
        padding: 0 10px !important;
      }
}
