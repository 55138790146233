.standard-content {
	width: 100%;
	max-width: 496px;
	margin: 0 auto 120px;
	padding: 0 15px;
	text-align: center;

	img {
		width: auto !important;
		max-width: 100% !important;
		height: auto !important;
	}

	h2 {
		@include h2;
		margin-bottom: 38px;
	}

	p,
	li {
		@include body-copy();

		a {
			color: get-color('red');
		}
	}

	p {
		text-align: center;
	}

	ul,
	ol {
		@include bullet-list();
	}

	p img {
		@include breakpoint(large) {
			max-width: none !important;
			width: calc(100% + 58%) !important;
			margin-left: -29%;
			margin-right: -29%;
		}
	}

	&__cta {
		text-align: center;
		padding-top: 40px;
	}
}
