.contact {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 120px;

	@include breakpoint(medium only) {
		padding: 0 20px;
	}

	&__details {
		@include breakpoint(small only) {
			padding: 0 20px 41px;
			text-align: center;
		}

		@include breakpoint(medium) {
			padding-right: 20px;
		}

		@include breakpoint(large) {
			padding-right: 40px;
		}

		h3 {
			@include subheading();
			font-size: 17px;
			font-weight: 700;
			margin-bottom: 34px;
		}

		p {
			@include body-copy();

			a {
				color: get-color('black');
				transition: all 0.3s ease-in-out;

				&:hover,
				&:focus,
				&:active {
					color: get-color('red');
				}
			}
		}
	}

	&__form {
		background-color: get-color('pale-grey');
		padding: 52px 20px 17px;

		@include breakpoint(large) {
			padding: 60px 40px 27px;
		}

		h2 {
			@include h2;
			margin-bottom: 40px;
		}

		.button {
			min-width: 117px;
		}
	}
}

.form label.error {
	font-size: 11px;
	transform: translateY(-2px);
}

form {
	input[type="checkbox"] {
		position: absolute;
		opacity: 0;
		top: 0;
		z-index: 1;

		+ label {
			position: relative;
			padding-left: 25px;
			padding-top: 3px;
			cursor: pointer;
			display: block;
			font-size: 10px;
			line-height: 14px;
			color: get-color('black');
			margin-bottom: 20px;

			&::before,
			&::after {
				content: "";
				position: absolute;
			}

			&::before {
				top: 1px;
				left: 0;
				width: 15px;
				height: 15px;
				background-color: get-color('white');
			}

			&::after {
				content: "✓";
				padding: 3px;
				top: 0px;
				left: 0px;
				width: 15px;
				height: 15px;
				opacity: 0;
				transition: opacity 0.3s ease-in-out;
			}

			a {
				color: inherit;
				transition: opacity 0.3s ease-in-out;

				&:hover,
				&:active {
					text-decoration: underline;
				}
			}
		}

		&:checked + label {
			&:after {
				opacity: 1;
			}
		}
	}

	.form__submit {
			text-align: right;

			@include breakpoint(small only) {
				text-align: left;
			}
		}
	}
